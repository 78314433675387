// import { withSSRContext } from 'aws-amplify'

// export default async function fetchAccessToken(context) {
//   try {
//     const { Auth: AmplifySSRAuth } = withSSRContext(context)
//     const currentSession = await AmplifySSRAuth.currentSession()

//     return currentSession.accessToken.jwtToken
//   }
//   catch {
//     return null
//   }
// }

export default function fetchAccessToken(context) {
  const cookies = context.$cookies.getAll()
  const tokenCookie = Object.entries(cookies).find(([k, _v]) => k.match(/CognitoIdentityServiceProvider.*accessToken/)) || []

  return tokenCookie[1]
}