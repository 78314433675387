<template>
  <div class="home">
    <carousel :slidesLoaded="slidesLoaded"
              :slides="slides"
              :show-controls="false"/>

    <component :is="homepageComponent"
               :shop-by-category-menu-items="shopByCategoryMenuItems"
               :instant-search-params="instantSearchParams"
               :banners-loaded="slidesLoaded"
               :banners="banners"/>

    <div class="grid-wrapper">
      <div class="crossed">
        <div class="content">Our Reviews</div>
      </div>

      <lazy-hydrate when-visible>
        <trust-box id="trustbox"
                   class="trustpilot-widget"
                   :trustboxData="trustboxData"/>
      </lazy-hydrate>

      <div class="crossed">
        <div class="content">Follow us</div>
      </div>

      <div class="social-container margin-bottom">
        <social-link type="instagram"/>
        <social-link type="pinterest"/>
        <social-link type="facebook"/>
      </div>

      <lazy-hydrate when-visible>
        <instagram-feed/>
      </lazy-hydrate>

      <div class="social-container margin-top desktop-only">
        <social-link type="instagram"/>
        <social-link type="pinterest"/>
        <social-link type="facebook"/>
      </div>

      <div class="hashtag-info">
        <span class="header header-block"
              role="heading"
              aria-level="2">
          Use our hashtag
        </span>
        <span class="nowrap">
          Share your designs with us! Use
          <a href="https://www.instagram.com/explore/tags/myscoutandnimble/"
             class="hashtag"
             aria-label="Share your designs with us"
             target="_blank">
            #myscoutandnimble
          </a>
        </span>
        on Instagram to show off your impeccable taste. Tag us at
        @scoutandnimble and images of your inspiring space could be showcased on
        one of our posts!
      </div>

      <designer-invation />
    </div>
  </div>
</template>

<script>
  import algoliasearch from 'algoliasearch/lite'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapGetters } from 'vuex'
  import Carousel from '@/components/Carousel'
  import DesignerInvation from '@/components/designer_steps/DesignerInvation'
  import InstagramFeed from '@/components/InstagramFeed'
  import ProductsLoader from '@/components/ProductsLoader'
  import SocialLink from '@/components/SocialLink'
  import TrustBox from '@/components/TrustBox'
  import { Hub as AmplifyHub } from 'aws-amplify'

  import ApiPage from '@/api/Page'
  import ApiSlide from '@/api/Slide'

  export default {
    name: 'home-view',
    components: {
      Carousel,
      DesignerInvation,
      HomepageCyberWeek: () => import('@/components/homepage/HomepageCyberWeek'),
      HomepageAnniversarySale: () => import('@/components/homepage/HomepageAnniversarySale'),
      HomepageLaborDaySale: () => import('@/components/homepage/HomepageLaborDaySale'),
      HomepageFallSale: () => import('@/components/homepage/HomepageFallSale'),
      HomepageChristmas: () => import('@/components/homepage/HomepageChristmas'),
      HomepageBlackFriday1: () => import('@/components/homepage/HomepageBlackFriday1'),
      HomepageBlackFriday2: () => import('@/components/homepage/HomepageBlackFriday2'),
      HomepageBlackFriday3: () => import('@/components/homepage/HomepageBlackFriday3'),
      HomepageBlackFriday4: () => import('@/components/homepage/HomepageBlackFriday4'),
      HomepageDefault: () => import('@/components/homepage/HomepageDefault'),
      InstagramFeed,
      LazyHydrate,
      ProductsLoader,
      SocialLink,
      TrustBox
    },
    data() {
      return {
        slides: [],
        banners: [],
        IGImages: [],
        categories: [],
        pageMetadata: { seo_title: '', seo_description: '' },
        trustboxData: {
          templateId: '53aa8912dec7e10d38f59f36',
          styleHeight: '140px',
          styleWidth: '100%',
          stars: '4,5'
        },
        slidesLoaded: false,
        hubListenerCancelToken: null
      }
    },
    computed: {
      ...mapGetters('globalData', ['getLayout']),
      ...mapGetters('auth', ['signedIn']),
      homepageComponent() {
        switch (this.getLayout) {
          case 'cyberWeek':
            return 'HomepageCyberWeek'
          case 'anniversarySale':
            return 'HomepageAnniversarySale'
          case 'laborDaySale':
            return 'HomepageLaborDaySale'
          case 'fallSale':
            return 'HomepageFallSale'
          case 'christmas':
            return 'HomepageChristmas'
          case 'blackFriday1':
            return 'HomepageBlackFriday1'
          case 'blackFriday2':
            return 'HomepageBlackFriday2'
          case 'blackFriday3':
            return 'HomepageBlackFriday3'
          case 'blackFriday4':
            return 'HomepageBlackFriday4'
          default:
            return 'HomepageDefault'
        }
      },
      instantSearchParams() {
        return {
          searchClient: this.searchClient,
          indexName: `${this.indexName}_created_at_timestamp_desc`,
          insights: true,
          future: {
            preserveSharedStateOnUnmount: true
          }
        }
      },
      searchClient() {
        return algoliasearch(
          this.$config.ALGOLIA_APPLICATION_ID,
          this.$config.ALGOLIA_API_KEY
        )
      },
      indexName() {
        return this.$config.ALGOLIA_VARIANTS_INDEX_NAME
      },
      shopByCategoryMenuItems() {
        return this.$store.getters['menu/getMenu']('shop_by_category')?.menu_items || []
      },
      trustpilotBusinessUnitId() {
        return this.$config.TRUSTPILOT_BUSINESS_UNIT_ID
      },
      defaultFilters() {
        return 'not_available:false'
      }
    },
    head() {
      return {
        title: this.pageMetadata.seo_title,
        link: [
          {
            rel: 'canonical',
            href: `${this.$config.baseUrl}${this.$route.path}`
          }
        ],
        meta: [
          {
            name: 'description',
            content: this.pageMetadata.seo_description
          }
        ],
        script: [
          {
            src: this.$config.TRUSTPILOT_WIDGET_URL,
            async: true,
            type: 'text/javascript'
          },
          {
            json: {
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: 'https://www.scoutandnimble.com/',
              potentialAction: {
                '@type': 'SearchAction',
                'target': 'https://www.scoutandnimble.com/search_results?search%5Bkeyword%5D={search_term_string}',
                'query-input': 'required name=search_term_string'
              }
            },
            type: 'application/ld+json'
          }
        ]
      }
    },
    methods: {
      loadImage(path) {
        return require('@/assets/images/' + path)
      },
      setHubListener () {
        if (!!this.hubListenerCancelToken) return

        this.hubListenerCancelToken =
          AmplifyHub.listen('auth', data => {
            if (['signIn'].includes(data.payload.event)) {
              this.loginCallback()
              this.removeHubListener()
            }
          })
      },
      removeHubListener () {
        if (typeof this.hubListenerCancelToken === 'function') this.hubListenerCancelToken()
      },
      loginCallback () {
        this.$store.dispatch('auth/setCurrentSession')
          .then(() => {
            this.$store.dispatch('auth/initialUser')
          })
          .catch(error => {
            console.error(error)
            let errorMsg = 'Something went wrong'

            this.$store.dispatch('modal/showModal', {
              component: 'Information',
              props: { info: errorMsg }
            })
          })
      },
      loadMetadata() {
        return ApiPage.getPage(this.$axios, 'home')
          .then(response => {
            this.pageMetadata = response.data.data
          })
          .catch(e => {
            console.log(e)
          })
      },
      loadSlides() {
        return ApiSlide.getSlides(this.$axios)
          .then(response => {
            this.slides = response.data.slides
            this.banners = response.data.banners
            this.slidesLoaded = true
          })
          .catch(() => {
            console.log('error')
          })
      }
    },
    created () {
      this.setHubListener()
    },
    async fetch() {
      await Promise.all([this.loadMetadata(), this.loadSlides()])
    },
    beforeDestroy () {
      this.removeHubListener()
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/stylesheets/homepage';

  .home {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100vw;
  }
</style>
